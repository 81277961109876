import React, { useContext, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';

import { showToast } from '@jobber/components/Toast';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { SocketContext } from '../../../SocketProvider';
import ConvertToJobsModal from '../Jobs/ConvertToJobsModal';
import { Button } from '@jobber/components/Button';
import { useJobContext } from '../contextAPI/JobContext';

function ConvertToJobButton({ sync }) {
  const [show, setShow] = useState(false);
  const { selectedIds } = useJobContext();
  const dispatch = useDispatch();
  const { socket, socketHandler } = useContext(SocketContext);
  const [comingMsg, setComingMsg] = useState(null);
  const [stopPrgress, setStopPrgress] = useState(null);
  const [progressValue, setProgressValue] = useState(1);
  const [dateModal, setDateModal] = useState(false);

  const userStore = useSelector((store) => store.user);
  const user = userStore?.user?.data;

  useEffect(() => {
    if (comingMsg) {
      setProgressValue(comingMsg?.progress);
    }
  }, [comingMsg]);

  useEffect(() => {
    if (stopPrgress) {
      showToast({
        message: stopPrgress?.message,
        variation: 'error'
      });
      setLoading(false);
    }
  }, [stopPrgress]);

  useEffect(() => {
    if (!socket) {
      const newSocket = io(process.env.REACT_APP_API_URL);
      socketHandler(newSocket);
    }

    if (socket) {
      socket.on(`Genie_Convert_Job_progress_${user?._id}`, (notify) => {
        setComingMsg(notify);
      });
      socket.on(`Genie_error_job_${user?._id}`, (notify) => {
        setStopPrgress(notify);
      });
    }
  }, [socket]);

  const joinRoom = async () => {
    if (socket && user?._id) {
      await socket.emit('joinGenieRoom', { userId: user?._id });
    }
  };

  useEffect(() => {
    joinRoom();
  }, [user?._id, socket]);

  function convertClickHandler() {
    if (selectedIds.length === 0) {
      showToast({
        message: 'Please select at least one quote',
        variation: 'error'
      });
      return;
    }
    setShow(true)
  }

  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading ? (
        <div className="mb-2">
          <ProgressBar animated variant="success" label={`${progressValue}%`} now={progressValue} />
          <p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }} className="mt-1">
            Do not perform any operation, while we are converting
          </p>
        </div>
      ) : (
        <>
          <Button label={'Convert to Jobs'} onClick={convertClickHandler} />
          {show && (
            <ConvertToJobsModal
              show={show}
              handleStart={() => {
                setShow(false);
                setLoading(true);
              }}
              handleStop={() => {
                setLoading(false);
                sync();
              }}
              handleClose={() => setShow(false)}
            />
          )}
        </>
      )}
    </>
  );
}

export default ConvertToJobButton;
