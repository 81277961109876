import { Divider } from "@jobber/components/Divider";
import { Heading } from "@jobber/components/Heading";
import React from "react";
import { Link } from "react-router-dom";
import { formatNumber, formatNumberWithSpaces, formatYearDateTimeDay } from "../../../components/Common/Validations";
import { Checkbox } from "@jobber/components/Checkbox";

const approvedColumns = ({ isAllSelected = false, selectedIds, handleMasterCheck = () => { }, handleRowCheck = () => { } } = {}) => [
    {
        accessorKey: "checkbox",
        header: () => (
            <div style={{ width: 20 }}>
                <Checkbox label="" checked={isAllSelected} onChange={(checked) => handleMasterCheck(checked)} />
            </div>
        ),
        cell: ({ row }) => (
            < div style={{ width: 20 }}>
                <Checkbox label="" checked={selectedIds.includes(row.original.quoteId)} onChange={(checked) => handleRowCheck(row.original.quoteId, checked)} />
            </div >
        ),
        maxSize: 60,
        isDisabled: true,
    },
    {
        accessorKey: "name",
        filterKey: "CLIENT_NAME",
        header: "Name",
        cell: ({ row }) => (
            <a href={row.original.jobberWebUri} target="_blank" className={`style__none`}>
                <Heading level={5}><span className='trunc d-block'>{row.original.clientName}</span></Heading>
                <p className=" trunc" style={{ color: 'var(--muted)' }}>{row.original.clientSecondaryName}</p>
            </a>
        ),
    },
    {
        accessorKey: "title",
        filterKey: "TITLE",
        header: "Title",
        cell: ({ row }) => row.original.quoteTitle,
    },
    {
        accessorKey: "address",
        filterKey: "ADDRESS",
        header: "Address",
        cell: ({ row }) => {
            // If quoteProperty is not an array, wrap it in an array
            const properties = Array.isArray(row.original.quoteProperty)
                ? row.original.quoteProperty
                : [row.original.quoteProperty];
            return properties.map((c, index, array) => (
                <React.Fragment key={index}>
                    {c.street}
                    {index !== array.length - 1 && <Divider direction="horizontal" />}
                </React.Fragment>
            ));
        }

    },
    {
        accessorKey: "zipCode",
        filterKey: "POSTAL_CODE",
        header: "Zip Code",
        cell: ({ row }) => row.original.quoteProperty.postalCode,
    },
    {
        accessorKey: "phone",
        filterKey: "CLIENT_PHONE",
        header: "Phone",
        cell: ({ row }) => row.original.clientPhone,
    },
    {
        accessorKey: "email",
        filterKey: "CLIENT_EMAIL",
        header: "Email",
        cell: ({ row }) => row.original.clientEmail,
    },
    {
        accessorKey: "details",
        header: "Details",
        cell: ({ row, table }) => (
            <>
                <Link
                    to={{ pathname: `/advance-quotes/approved/${row.original._id}/details` }}
                    className="text-primary animate text-decoration-underline"
                >
                    Details
                </Link>
            </>
        ),
    },
    {
        accessorKey: "totalCost",
        filterKey: "TotalCost",
        header: "Total Cost",
        cell: ({ row }) => `$${formatNumberWithSpaces(formatNumber(row.original.totalCost)) || 0}`,

    },
    {
        accessorKey: "date",
        filterKey: "QUOTE_CREATED_AT",
        header: "Created Date",
        cell: ({ row }) => formatYearDateTimeDay(row.original.quote_createdAt),
    },
    {
        accessorKey: "approvedDate",
        filterKey: "APPROVED_AT",
        header: "Approved Date",
        cell: ({ row }) => (row.original.approved_at ? formatYearDateTimeDay(row.original.approved_at) : "-"),
    },
];

export default approvedColumns;
