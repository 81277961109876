import { Divider } from "@jobber/components/Divider";
import { Heading } from "@jobber/components/Heading";
import React, { useMemo } from "react";
import { formatYearDateTimeDay } from "../../../components/Common/Validations";
import { Checkbox } from "@jobber/components/Checkbox";
import { Button } from "@jobber/components/Button";

const jobColumns = ({ isAllSelected = false, selectedIds, handleMasterCheck = () => { }, handleRowCheck = () => { } } = {}) => {
    const columns = useMemo(() => [
        {
            accessorKey: "checkbox",
            header: () => (
                <div style={{ width: 20 }}>
                    <Checkbox label="" checked={isAllSelected} onChange={(checked) => handleMasterCheck(checked)} />
                </div>
            ),
            cell: ({ row }) => (
                < div style={{ width: 20 }}>
                    <Checkbox label="" checked={selectedIds.includes(row.original.jobId)} onChange={(checked) => handleRowCheck(row.original.jobId, checked)} />
                </div >
            ),
            maxSize: 60,
            isDisabled: true,
        },
        {
            accessorKey: "name",
            filterKey: "CLIENT_NAME",
            header: "Name",
            cell: ({ row }) => <Heading level={5}><span className='trunc d-block'>{row.original.client.name}</span></Heading>,
        },
        {
            accessorKey: "title",
            filterKey: "TITLE",
            header: "Title",
            cell: ({ getValue }) => (
                <p>
                    {getValue() || ''}
                </p>
            ),
        },
        {
            accessorKey: "instructions",
            filterKey: "INSTRUCTIONS",
            header: "Instructions",
            cell: ({ getValue }) => (
                <p>
                    {getValue() || ''}
                </p>
            ),
        },
        {
            accessorKey: "property",
            filterKey: "PROPERTY",
            header: "Property",
            cell: ({ row }) => {
                const address = row.original.property.address;
                // Convert the address object into an array of [key, value] pairs and map over it.
                return (
                    <>
                        {Object.entries(address).map(([key, value], index, array) => (
                            <React.Fragment key={index}>
                                <span> {value}</span>
                                {index !== array.length - 1 && <span> </span>}
                            </React.Fragment>
                        ))}
                    </>
                );
            },
        },

        {
            accessorKey: "schedule",
            filterKey: "SCHEDULE",
            header: "Schedule",
            cell: ({ row }) => formatYearDateTimeDay(row.original.visitSchedule.startDate),
        },
        {
            accessorKey: "status",
            filterKey: "STATUS",
            header: "Status",
            cell: ({ row }) => (
                row.original.jobStatus === "archived" ? (
                    <span className="badge bg-secondary-subtle text-secondary">Archived</span>
                ) : row.original.jobStatus === "requires_invoicing" ? (
                    <span className="badge bg-danger-subtle text-danger">Requires Invoicing</span>
                ) : (
                    <span className="badge bg-success-subtle text-success">{row.original.jobStatus}</span>
                )
            ),
        },
        {
            accessorKey: "type",
            filterKey: "TYPE",
            header: "Type",
            cell: ({ row }) => (
                row?.original?.jobType === 'RECURRING' ? (
                    <div className="badge text-bg-success">{'Recurring'}</div>
                ) : row?.original?.jobType === 'ONE_OFF' ? (
                    <div className="badge text-bg-info">{'One Off'}</div>
                ) : (
                    <div className="badge text-bg-primary">{row?.original?.jobType}</div>
                )
            ),
        },
        {
            accessorKey: "total",
            filterKey: "TOTAL",
            header: "Total",
            cell: ({ getValue }) => (
                <p>
                    ${getValue() || '0'}
                </p>
            ),
        },
        {
            accessorKey: "createdAt",
            filterKey: "CREATED_AT",
            header: "Created at",
            cell: info => (
                <p>
                    {formatYearDateTimeDay(info.row.original.createdAt)}
                </p>
            ),
        },
        {
            accessorKey: "updatedAt",
            filterKey: "UPDATED_AT",
            header: "Updated at",
            cell: info => (
                <p>
                    {formatYearDateTimeDay(info.row.original.updatedAt)}
                </p>
            ),
        },
        {
            accessorKey: "closedAt",
            filterKey: "CLOSED_AT",
            header: "Closed at",
            cell: info => (
                <p>
                    {formatYearDateTimeDay(info.row.original.completedAt)}
                </p>
            ),
        },
        {
            accessorKey: "reopen",
            header: "Actions",

            cell: ({ row, table }) => (
                <>
                    <Button label="Reopen Job" onClick={() => table.options.meta?.reOpenHandler(row.original)} type="secondary" size="small" />
                    <Button icon="trash" onClick={() => table.options.meta?.deleteHandler(row.original._id, "Job deleted successfully")} variation="destructive" type="secondary" size="small" />
                </>
            ),
            isDisabled: true,
        },

    ]);
    return columns;
};

export default jobColumns;
