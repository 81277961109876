import React, { useMemo, useRef, useState } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
} from '@tanstack/react-table';
import { InputText } from '@jobber/components/InputText';
import { Select, Option } from '@jobber/components/Select';
import Pagination from '../../components/Common/Pagination';
import { getFromServer, postToServer } from '../../components/Common/requests';
import { Glimmer } from '@jobber/components/Glimmer';
import { showToast } from '@jobber/components/Toast';
import { useDispatch } from 'react-redux';
import { setFilterQuery } from '../../store/filterSlice';
import { Spinner } from '@jobber/components/Spinner';
import { Button } from '@jobber/components/Button';
import { Checkbox } from '@jobber/components/Checkbox';
import { Dropdown, DropdownItem } from 'react-bootstrap';
import { Combobox } from '@jobber/components/Combobox';
import { useFilter } from './FilterQuery/FilterQuery';
import {
    DndContext,
    MouseSensor,
    TouchSensor,
    KeyboardSensor,
    useSensor,
    useSensors,
    closestCenter,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    horizontalListSortingStrategy,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import useTableColumns from './useTableColumns';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useDebounce } from '../../utils/useDebounce';

// Draggable header cell
const DraggableTableHeader = ({ header }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: header.id,
    });

    const headerStyle = {
        position: 'relative',
        transition,
        transform: CSS.Translate.toString(transform),
        width: `calc(var(--header-${header?.id}-size) * 1px)`,
    };

    return (
        <th ref={setNodeRef} colSpan={header.colSpan} style={headerStyle} className="border p-2">
            <div {...attributes} {...listeners} className="headDND" />
            <div className='d-flex justify-content-between align-items-center'>
                {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
            </div>
            {header.column.getCanResize() && (
                <div
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                />
            )}
        </th>
    );
};

const DragAlongCell = ({ cell, row }) => {
    const { isDragging, setNodeRef, transform, transition } = useSortable({
        id: cell.column.id,
    });

    const style = {
        position: 'relative',
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 0,
    };

    return (
        <td style={style} ref={setNodeRef} className="p-2 position-relative">
            <a href={row.original.jobberWebUri} target="_blank" rel="noreferrer" className="style__none" style={{ position: 'absolute', inset: 0 }} />
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
    );
};

export default function ClientTable({ tagCloud, reload }) {
    const dispatch = useDispatch();

    // Pagination and table state
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const { updateFilter, buildQuery, savedFilterId, addFilterList } = useFilter();

    const [selected, setSelected] = useState(() => {
        const savedFilter = localStorage.getItem('savedFilter');
        return savedFilter ? [{ id: savedFilter, label: savedFilter }] : [];
    });

    // Column Arrangement & Resizing State
    const localSettings = JSON.parse(localStorage.getItem('userSettings'));
    const [visibleColumns, setVisibleColumns] = useState(localSettings?.columnVisibility || {});
    const [columnOrder, setColumnOrder] = useState(localSettings?.columnOrder);
    const [columnSizing, setColumnSizing] = useState(localSettings?.columnWidths || {});

    // Mutation for saving user settings
    const { mutate: mutateSettings, isLoading: isSavePending } = useMutation({
        mutationFn: (body) => postToServer('table-settings', body),
        onError: (error) => {
            if (error && error.status === 404) {
                showToast({ message: 'Error 404: Table settings endpoint not found.', variation: 'error' });
            } else {
                showToast({ message: error.message || error.error || 'An error occurred', variation: 'error' });
            }
        },
        onSuccess: (data) => {
            localStorage.setItem('userSettings', JSON.stringify(data.data));
            showToast({ message: 'User settings updated successfully' });
        },
    });

    // Save settings handler using mutation
    const saveUserSettingsHandler = () => {
        const body = {
            columnOrder: columnOrder,
            columnVisibility: visibleColumns,
            columnWidths: columnSizing,
        };
        mutateSettings(body);
    };

    const queryString = buildQuery();

    // TanStack Query
    const { data: clientResponse, isLoading, refetch } = useQuery({
        queryKey: ['clients', queryString, savedFilterId, currentPage, limit],
        queryFn: ({ signal }) =>
            getFromServer(`clients?${queryString}${savedFilterId ? `&filterId=${savedFilterId}` : ''}`, signal),
        staleTime: 1000 * 60 * 5,
        retry: 2,
    });

    // Importing Table Columns
    const columns = useTableColumns(updateFilter, tagCloud);

    // React Table TanStack
    const table = useReactTable({
        data: clientResponse?.data || [],
        columns,
        defaultColumn: {
            minSize: 150,
            maxSize: 800,
        },
        state: { columnVisibility: visibleColumns, columnOrder, columnSizing },
        onColumnVisibilityChange: setVisibleColumns,
        onColumnOrderChange: setColumnOrder,
        onColumnSizingChange: setColumnSizing,
        columnResizeMode: 'onChange',
        manualSorting: true,
        getCoreRowModel: getCoreRowModel(),
    });

    // columnSizeVars method to compute and memoize widths
    const columnSizeVars = useMemo(() => {
        const headers = table.getFlatHeaders();
        const colSizes = {};
        for (let i = 0; i < headers.length; i++) {
            const header = headers[i];
            colSizes[`--header-${header.id}-size`] = header.getSize();
            colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
        }
        localStorage.setItem('userSettings', JSON.stringify({ ...localSettings, columnWidths: columnSizing }));
        return colSizes;
    }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

    // DnD sensors for dragging columns
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            const oldIndex = columnOrder.indexOf(active.id);
            const newIndex = columnOrder.indexOf(over.id);
            const newOrder = arrayMove(columnOrder, oldIndex, newIndex);
            localStorage.setItem('userSettings', JSON.stringify({ ...localSettings, columnOrder: newOrder }));
            setColumnOrder(newOrder);
        }
    };

    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownToggleRef = useRef(null);
    const toggleDropdown = () => setShowDropdown((prev) => !prev);

    const [search, setSearch] = useState('');
    const debouncedUpdateFilter = useDebounce(updateFilter, 500);

    function formatLabel(key) {
        return key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
    }

    // Clear filters function
    const clearFilters = () => {
        dispatch(setFilterQuery({}));
        localStorage.removeItem('tagCloudInclude');
        localStorage.removeItem('tagCloudExclude');
        addFilterList({});
        updateFilter('limit', 10);
        setSearch('');
        setCurrentPage(1);
        refetch();
    };

    return (
        <div>
            <div className="search__sticky">
                <div className="d-flex justify-content-between mb-3">
                    <div className="col-lg-3 col-md-4 d-flex gap-2 align-items-center">
                        <p className="pt-3">Show</p>
                        <Select
                            size="small"
                            onChange={(e) => {
                                updateFilter('limit', e);
                                setLimit(e);
                            }}
                            value={limit}
                        >
                            <Option value="10">10</Option>
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                        </Select>
                        <p className="pt-3">entries</p>
                    </div>

                    <div className="col-md-5 d-flex gap-2 align-items-center">
                        <Combobox
                            onSelect={(e) => {
                                setSelected(e);
                                updateFilter('filter', e?.[0]?.id);
                            }}
                            selected={selected}
                            label="Status"
                        >
                            <Combobox.Option id="All" label="All" />
                            <Combobox.Option id="LeadAndActive" label="Leads and Active" />
                            <Combobox.Option id="Lead" label="Leads" />
                            <Combobox.Option id="Active" label="Active" />
                            <Combobox.Option id="Archived" label="Archived" />
                        </Combobox>
                        <InputText
                            placeholder="Search"
                            name="firstName"
                            size="small"
                            value={search}
                            onChange={(e) => {
                                debouncedUpdateFilter('searchTerm', e);
                                setSearch(e);
                                setCurrentPage(1);
                            }}
                        />
                    </div>
                </div>
                <div className="mb-3 d-flex gap-3" style={{ paddingBottom: '1rem' }}>
                    <Button label="Manage Columns" ref={dropdownToggleRef} onClick={toggleDropdown} />
                    <Button label="Clear Filters" type="secondary" onClick={clearFilters} />
                    {isSavePending ? (
                        <Spinner size="base" />
                    ) : (
                        <Button label="Save Settings" type="secondary" onClick={saveUserSettingsHandler} />
                    )}
                </div>
            </div>

            {/* Dropdown for toggling column visibility */}
            {showDropdown && (
                <div className="dropdown__sticky">
                    <Dropdown show={showDropdown}>
                        <Dropdown.Menu>
                            <DropdownItem>Show and Hide Columns</DropdownItem>
                            <div className="ms-3 d-flex flex-column gap-1">
                                {table.getAllLeafColumns().map((column) => {
                                    return (
                                        <div key={column.id} className="px-1">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={column.getIsVisible()}
                                                    onChange={column.getToggleVisibilityHandler()}
                                                />{' '}
                                                {formatLabel(column.id)}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )}

            {/* Table with DnD for whole‑column dragging */}
            <div>
                <div
                    id="client__table"
                    className="mt-2 rounded-lg clienttable__sticky bg-white overflow-y-auto"
                >
                    <DndContext
                        collisionDetection={closestCenter}
                        modifiers={[restrictToHorizontalAxis]}
                        onDragEnd={handleDragEnd}
                        sensors={sensors}
                    >
                        <table
                            className="table table-hover border border-collapse border-gray-300"
                            style={{
                                ...columnSizeVars,
                                width: table.getTotalSize(),
                            }}
                        >
                            <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <SortableContext
                                        key={headerGroup.id}
                                        items={columnOrder}
                                        strategy={horizontalListSortingStrategy}
                                    >
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map((header) => (
                                                <DraggableTableHeader key={header.id} header={header} />
                                            ))}
                                        </tr>
                                    </SortableContext>
                                ))}
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    Array.from({ length: 2 }).map((_, index) => (
                                        <tr key={index}>
                                            {table.getAllLeafColumns().map((col) => (
                                                <td key={col.id}>
                                                    <Glimmer shape="rectangle" size="base" timing="speed" />
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                ) : table.getRowModel().rows.length === 0 ? (
                                    <tr>
                                        <td colSpan={columns.length} style={{ textAlign: 'center', padding: '2rem' }}>
                                            No data found
                                        </td>
                                    </tr>
                                ) : (
                                    table.getRowModel().rows.map((row) => (
                                        <tr key={row.id} className="border">
                                            {row.getVisibleCells().map((cell) => (
                                                <SortableContext
                                                    key={cell.id}
                                                    items={columnOrder}
                                                    strategy={horizontalListSortingStrategy}
                                                >
                                                    <DragAlongCell key={cell.id} row={row} cell={cell} />
                                                </SortableContext>
                                            ))}
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </DndContext>
                </div>
            </div>

            {/* Pagination */}
            <div className="d-md-flex justify-content-between mt-4">
                <div>
                    <p>{clientResponse?.totalItems ?? 0} entries</p>
                </div>
                {clientResponse && clientResponse.totalPages > 1 && (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={clientResponse.totalPages}
                        onPageChange={(page) => {
                            setCurrentPage(page);
                            updateFilter('page', page);
                        }}
                    />
                )}
            </div>
        </div>
    );
}

