import React, { useEffect, useMemo, useState } from 'react';
import { Select, Option } from '@jobber/components/Select';
import Pagination from '../../../components/Common/Pagination';
import { getFromServer, postToServer, deleteFromServer } from '../../../components/Common/requests';
import { showToast } from '@jobber/components/Toast';
import './table.css';
import ShowAppliedFilters from '../../../components/Common/ShowAppliedFilters';
import { useJobContext } from '../contextAPI/JobContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { InputText } from '@jobber/components/InputText';
import { Button } from '@jobber/components/Button';
import { Glimmer } from '@jobber/components/Glimmer';
import { Spinner } from '@jobber/components/Spinner';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { DndContext, MouseSensor, TouchSensor, KeyboardSensor, useSensor, useSensors, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { Col, Dropdown, DropdownItem, Row } from 'react-bootstrap';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { useDebounce } from '../../../utils/useDebounce';
import { useAutomationFilters } from '../FilterQuery/AutomationFilterQuery';
import approvedColumns from './NewApprovedCol';
import DropdownFilters from './Filters/DropdownFilters';
import ConvertToJobButton from './ConvertToJobButton';
import FiltersIndex from './FiltersIndex';

const SortableHeader = ({ header, onSort, getIconStyle }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: header.id });
    const headerStyle = {
        position: 'relative',
        transition,
        transform: CSS.Translate.toString(transform),
        width: `calc(var(--header-${header?.id}-size) * 1px)`,
        zIndex: isDragging ? 1 : 0,
    };
    const { isDisabled, header: headerVal, filterKey } = header.column.columnDef;
    return (
        <th
            ref={!!isDisabled ? undefined : setNodeRef}
            colSpan={header.colSpan}
            style={headerStyle}
            className="border p-2"
        >
            <div {...attributes} {...listeners} className={`headDND ${!!isDisabled && 'disabledDND'}`} />
            <div className='d-flex justify-content-between align-items-center'>
                <b style={{ zIndex: 2 }} className='position-relative'>
                    {header.isPlaceholder ? null : flexRender(headerVal, header.getContext())}
                </b>
                {!!filterKey &&
                    <div className='icon-container' onClick={(e) => { e.stopPropagation(); onSort(filterKey); }}>
                        <IoMdArrowDropup className={`icon-up ${getIconStyle(filterKey, 'ASCENDING')}`} />
                        <IoMdArrowDropdown className={`icon-down ${getIconStyle(filterKey, 'DESCENDING')}`} />
                    </div>
                }
            </div>
            <div
                onMouseDown={header.getResizeHandler()}
                onTouchStart={header.getResizeHandler()}
                className={`${!!isDisabled ? 'd-none' : 'resizer'} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
            />
        </th>
    );
};

const DragAlongCell = ({ cell }) => {
    const { isDragging, setNodeRef, transform, transition } = useSortable({
        id: cell.column.id,
    });
    const style = {
        position: 'relative',
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 0,
    };
    return (
        <td style={style} ref={setNodeRef} className="p-2">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
    );
};

export default function NewApprovedTable() {
    const { updateFilter, buildQuery, filters } = useAutomationFilters();
    // const [currentPage, setCurrentPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(() => {
        const savedPage = localStorage.getItem('aTPn');
        return savedPage ? Number(savedPage) : 1;
    });
    const [limit, setLimit] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ASCENDING' });
    const [search, setSearch] = useState('');
    const debouncedUpdateFilter = useDebounce(updateFilter, 500);

    const localSettings = JSON.parse(localStorage.getItem('approvedQuotesSettings')) || {};
    const [columnVisibility, setColumnVisibility] = useState(localSettings?.columnVisibility || {});
    const [columnOrder, setColumnOrder] = useState(() => Array.isArray(localSettings?.columnOrder)
        ? localSettings?.columnOrder
        : Object.entries(localSettings?.columnOrder || {})
            .sort((a, b) => a[1] - b[1])
            .map(([key]) => key.replace(/^col/, '')));
    const [columnSizing, setColumnSizing] = useState(localSettings?.columnWidths || {});
    const [showDropdown, setShowDropdown] = useState(false);
    const toggleDropdown = () => setShowDropdown(!showDropdown);

    const onSort = (key) => {
        updateFilter('sort_key', key);
        if (sortConfig?.key === key && sortConfig?.direction === 'ASCENDING') {
            setSortConfig({ key, direction: 'DESCENDING' });
            updateFilter('sort_direction', 'DESCENDING');
        } else {
            setSortConfig({ key, direction: 'ASCENDING' });
            updateFilter('sort_direction', 'ASCENDING');
        }
    };

    const queryString = buildQuery();
    const { data: tableData, isLoading, refetch } = useQuery({
        queryKey: ['quoteTitles', filters, currentPage, queryString, limit, debouncedUpdateFilter],
        queryFn: ({ signal }) => getFromServer(`job-page?${queryString}`, signal),
        staleTime: 1000 * 60 * 5,
        retry: 2,
    });

    const data = tableData?.data?.quotes || [];
    const totalPage = tableData?.data?.totalPages || 1;
    const entries = tableData?.data?.totalQuotes || 0;

    // Save settings mutation
    const { mutate: mutateSettings, isPending: isSavePending } = useMutation({
        mutationFn: (body) => postToServer('approved-quote-Settings', body),
        onError: (error) => {
            if (error && error.status === 404) {
                showToast({ message: 'Error 404: Table settings endpoint not found.', variation: 'error' });
            } else {
                showToast({ message: error.message || error.error || 'An error occurred', variation: 'error' });
            }
        },
        onSuccess: () => {
            showToast({ message: 'Approved Quotes settings updated successfully' });
            refetch();
        },
    });

    const saveUserSettingsHandler = () => {
        const body = {
            columnOrder,
            columnVisibility,
            columnWidths: columnSizing,
        };
        localStorage.setItem('approvedQuotesSettings', JSON.stringify(body));
        mutateSettings(body);
    };

    const getIconStyle = (key, direction) => {
        return sortConfig.key === key && sortConfig.direction === direction
            ? 'fs-5 text-dark'
            : 'fs-5 text-muted opacity-25';
    };

    // Row selection
    const { selectedIds, setSelectedIds } = useJobContext();
    const [isAllSelected, setIsAllSelected] = useState(false);
    const handleRowCheck = (id, isChecked) => {
        if (isChecked) {
            if (!selectedIds.includes(id)) {
                const newSelected = [...selectedIds, id];
                setSelectedIds(newSelected);
                if (newSelected.length === data.length) {
                    setIsAllSelected(true);
                }
            }
        } else {
            const newSelected = selectedIds.filter(selectedId => selectedId !== id);
            setSelectedIds(newSelected);
            // master should be unchecked.
            setIsAllSelected(false);
        }
    };

    const handleMasterCheck = (isChecked) => {
        setIsAllSelected(isChecked);
        if (isChecked) {
            const allIds = data?.map(item => item.quoteId);
            setSelectedIds([...allIds]);
        } else {
            setSelectedIds([]);
        }
    };

    const columns = approvedColumns({ isAllSelected, selectedIds, handleMasterCheck, handleRowCheck });


    const table = useReactTable({
        data,
        columns,
        defaultColumn: { minSize: 100, maxSize: 600 },
        getCoreRowModel: getCoreRowModel(),
        manualSorting: true,
        state: { columnVisibility, columnOrder, columnSizing },
        onColumnSizingChange: setColumnSizing,
        columnResizeMode: 'onChange',
        onColumnVisibilityChange: setColumnVisibility,
        onColumnOrderChange: setColumnOrder,
    });

    function formatLabel(key) {
        return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    }

    const columnSizeVars = useMemo(() => {
        const headers = table.getFlatHeaders();
        const colSizes = {};
        headers.forEach(header => {
            colSizes[`--header-${header.id}-size`] = header.getSize();
            colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
        });
        localStorage.setItem('approvedQuotesSettings', JSON.stringify({ ...localSettings, columnWidths: columnSizing }));
        return colSizes;
    }, [table.getState().columnSizingInfo, table.getState().columnSizing, localSettings, columnSizing]);

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (!active || !over || active.id === 'checkbox' || over.id === 'checkbox') return;
        if (active && over && active.id !== over.id) {
            setColumnOrder((oldOrder) => {
                const activeIndex = oldOrder.indexOf(active.id);
                const overIndex = oldOrder.indexOf(over.id);
                const newOrder = arrayMove(oldOrder, activeIndex, overIndex);
                localStorage.setItem('approvedQuotesSettings', JSON.stringify({ ...localSettings, columnOrder: newOrder }));
                return [...newOrder];
            });
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        updateFilter('page', page);
    };

    const { data: quoteTitle } = useQuery({
        queryKey: ['quoteTitle'],
        queryFn: () => getFromServer('quoteTitles'),
      })

    return (
        <>
            <div className="mb-5">
                <ShowAppliedFilters page="automation" />
            </div>
            <div className="row mb-3">
                <div className="col-lg-3 col-md-4 d-flex gap-2 align-items-center">
                    <p className="pt-3">Show</p>
                    <Select
                        size="small"
                        onChange={(e) => {
                            updateFilter('limit', e);
                            setLimit(e);
                        }}
                        value={limit}
                    >
                        <Option value="all">All (Takes time to load)</Option>
                        <Option value="10">10</Option>
                        <Option value="20">20</Option>
                        <Option value="50">50</Option>
                    </Select>
                    <p className="pt-3">entries</p>
                </div>
                <div className="col-md-9 mt-2">
                    <div className="d-flex gap-2 flex-wrap justify-content-end align-items-center" style={{ paddingBottom: '1rem' }}>
                        <DropdownFilters quoteTitles={quoteTitle?.data} />
                        <ConvertToJobButton sync={refetch} />
                        <FiltersIndex />
                    </div>
                </div>
            </div>
            <Row>
                <Col md={6}>
                    <div className="d-flex gap-3 pt-3">
                        <InputText
                            placeholder="Search"
                            size="small"
                            value={search}
                            onChange={(e) => {
                                debouncedUpdateFilter('searchTerm', e);
                                setSearch(e);
                                if (e === '') {
                                    setCurrentPage(1);
                                    updateFilter('page', 1);
                                }
                            }}
                        />
                        <div style={{ position: 'relative' }}>
                            <Button label="Manage Columns" onClick={toggleDropdown} />
                            {showDropdown && (
                                <div className="dropdown__sticky">
                                    <Dropdown show={showDropdown}>
                                        <Dropdown.Menu>
                                            <DropdownItem onClick={toggleDropdown}>Show and Hide Columns</DropdownItem>
                                            <div className="ms-3 d-flex flex-column gap-[3px]">
                                                {table.getAllLeafColumns()
                                                    .filter(column => column.id !== 'checkbox')
                                                    .map(column => (
                                                        <div key={column.id} className="px-1">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={column.getIsVisible()}
                                                                    onChange={column.getToggleVisibilityHandler()}
                                                                />{' '}
                                                                {formatLabel(column.id)}
                                                            </label>
                                                        </div>
                                                    ))}
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            )}
                        </div>
                        <div>
                            {isSavePending ? (
                                <Spinner size="base" />
                            ) : (
                                <Button label="Save Settings" type="secondary" onClick={saveUserSettingsHandler} />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="mt-3 rounded-lg bg-white overflow-auto position-relative">
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    modifiers={[restrictToHorizontalAxis]}
                    onDragEnd={handleDragEnd}
                >
                    <table
                        className="table table-hover border-collapse border border-gray-300"
                        style={{ ...columnSizeVars, width: table.getTotalSize() }}
                    >
                        <thead className="bg-gray-100">
                            {table.getHeaderGroups().map(headerGroup => (
                                <SortableContext
                                    key={headerGroup.id}
                                    items={columnOrder}
                                    strategy={horizontalListSortingStrategy}
                                >
                                    <tr key={headerGroup.id} className="pt-4" style={{ height: '60px' }}>
                                        {headerGroup.headers.map(header => (
                                            <SortableHeader
                                                key={header.id}
                                                header={header}
                                                onSort={onSort}
                                                getIconStyle={getIconStyle}
                                            />
                                        ))}
                                    </tr>
                                </SortableContext>
                            ))}
                        </thead>
                        <tbody>
                            {isLoading
                                ? Array.from({ length: 2 }).map((_, index) => (
                                    <tr key={index}>
                                        {table.getVisibleFlatColumns().map(column => (
                                            <td key={column.id}>
                                                <Glimmer shape="rectangle" size="base" timing="speed" />
                                            </td>
                                        ))}
                                    </tr>
                                ))
                                : data && data.length > 0
                                    ? table.getRowModel().rows.map(row => (
                                        <tr key={row.id} className="border-bottom">
                                            {row.getVisibleCells().map(cell => (
                                                <SortableContext
                                                    key={cell.id}
                                                    items={columnOrder}
                                                    strategy={horizontalListSortingStrategy}
                                                >
                                                    <DragAlongCell key={cell.id} cell={cell} />
                                                </SortableContext>
                                            ))}
                                        </tr>
                                    ))
                                    : (
                                        <tr style={{ height: '300px', display: 'grid', placeItems: 'center' }}>
                                            <p>No data found</p>
                                        </tr>
                                    )}
                        </tbody>
                    </table>
                </DndContext>
            </div>
            <div className="d-md-flex justify-content-between mt-3">
                <div>
                    <p>{entries} entries</p>
                </div>
                {totalPage > 1 && (
                    <div>
                        <Pagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
        </>
    );
}
