import React, { useEffect, useState } from 'react';
import { Heading } from '@jobber/components/Heading';
import Table from './Table/ApprovedTable';
import { useDispatch, useSelector } from 'react-redux';
import { getFromServer } from '../../components/Common/requests';
import { addUniqueIds } from '../../components/Common/Validations';
import { setAllTags, setLineItems, setTitles } from '../../store/tagsSlice';
import { showToast } from '@jobber/components/Toast';
import ResetSidebar from '../../components/Common/ResetSidebar';
import { JobProvider } from './contextAPI/JobContext';
import NewApprovedTable from './Table/NewQuotesTable';

function ApprovedQuotes() {
  const dispatch = useDispatch();
  const tagSlice = useSelector((store) => store.tag);

  useEffect(() => {
    const init = async () => {
      const [result, lineItems] = await Promise.all([getFromServer('Client-Tags'), getFromServer('line-items')]);
      if (result.status) {
        const addUniqueId = addUniqueIds(result.data);
        dispatch(setAllTags(addUniqueId));
      } else {
        showToast({
          message: result.message || result.error,
          variation: 'error'
        });
      }
      if (lineItems.status) {
        console.log(lineItems.data?.lineItems);
        dispatch(setLineItems(lineItems.data?.lineItems));
        dispatch(setTitles(lineItems.data?.quoteTitles));
      } else {
        showToast({
          message: lineItems.message || lineItems.error,
          variation: 'error'
        });
      }
    };
    if (tagSlice?.allTags?.length <= 0 || tagSlice?.lineItems?.length <= 0) {
      init();
    }
  }, []);

  return (
    <>
      <ResetSidebar />
      <div className="row">
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <Heading level={1}>Approved Quotes</Heading>
          </div>
          <JobProvider>
            {/* <Table reload={() => { }} /> */}
            <NewApprovedTable />
          </JobProvider>
        </div>
      </div>
    </>
  );
}

export default ApprovedQuotes;
